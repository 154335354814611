import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const ContactSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Contato",
    paragraph: "",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <p>
            Sinta-se à vontade para entrar em contato com a Easy Access através
            dos nossos canais de comunicação.
          </p>
          <h5 className="mt-0 mb-12">Telefone</h5>
          <p>(35) 3427-3396</p>
          <h5 className="mt-0 mb-12">E-mail</h5>
          <p>contato@ezaccess.com.br</p>
          <h5 className="mt-0 mb-12">Redes Sociais</h5>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "50%",
            }}
          >
            <a
              href="https://www.linkedin.com/company/ezaccess/"
              style={{ marginRight: "20px" }}
            >
              <FaLinkedin size={50} color="rgb(1, 48, 63)" />
            </a>
            <a href="https://wa.me/553534273396">
              <FaWhatsapp size={50} color="rgb(1, 48, 63)" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

ContactSplit.propTypes = propTypes;
ContactSplit.defaultProps = defaultProps;

export default ContactSplit;
